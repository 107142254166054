import intlTelInput from 'intl-tel-input';
import telUtils from 'intl-tel-input/build/js/utils';
import '../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import qs from 'querystring';

const url = './sendmail.php';

const inputPhone = document.querySelector('#phone');
const emailInput = document.querySelector('#email');
const nameInput = document.querySelector('#name');
const errorMsg = document.querySelector('.error-ph');
const validMsg = document.querySelector('.valid-ph');
const validEmail = document.querySelector('.valid-email');
const errorEmail = document.querySelector('.error-email');
const validName = document.querySelector('.valid-name');
const errorName = document.querySelector('.error-name');
const closeBtn = document.querySelector('.js-modal-close');
const popUp = document.querySelector('.js-modal-layer');
const popUptext = document.querySelector('.popup-text');

const iti = intlTelInput(inputPhone, {
  preferredCountries: ['ru', 'by', 'kz', 'ua'],
  formatOnDisplay: true,
  utlisScript: telUtils,
  geoIpLookup: 'auto',
  placeholderNumberType: 'MOBILE',
});

const reset = () => {
  inputPhone.classList.remove('error');
  errorMsg.innerHTML = '';
  errorMsg.classList.add('hide');
  validMsg.classList.add('hide');
};

inputPhone.addEventListener('blur', () => {
  reset();
  if (inputPhone.value.trim()) {
    if (iti.isValidNumber()) {
      validMsg.classList.remove('hide');
    } else {
      inputPhone.classList.add('error');
      iti.getValidationError();
      errorMsg.classList.remove('hide');
    }
  }
});

inputPhone.addEventListener('change', reset);
inputPhone.addEventListener('keyup', reset);

// валидация поля ввода email
const emailIsValid = () => {
  const testInp = (/^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d.A-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(emailInput.value));
  if (testInp) {
    validEmail.classList.remove('hide');
    emailInput.setAttribute('valid', true);
  } else {
    errorEmail.classList.remove('hide');
    emailInput.setAttribute('valid', false);
  }
};

// валидация поля имени
const nameIsValid = () => (nameInput.value ? validName.classList.remove('hide') : errorName.classList.remove('hide'));

// очистка input email и name
const clearEmail = () => {
  if (emailInput) {
    errorEmail.classList.add('hide');
    validEmail.classList.add('hide');
  }
};

const clearName = () => {
  if (emailInput) {
    errorName.classList.add('hide');
    validName.classList.add('hide');
  }
};

emailInput.addEventListener('blur', emailIsValid);
emailInput.addEventListener('change', clearEmail);

nameInput.addEventListener('blur', nameIsValid);
nameInput.addEventListener('keydown', clearName);
nameInput.addEventListener('change', clearName);


// очистка формы
const clearForm = () => {
  nameInput.value = null;
  inputPhone.value = null;
  emailInput.value = null;
  clearName();
  clearEmail();
  reset();
};

// открытие popup
const openPopUp = () => {
  popUp.classList.add('active');
  document.querySelector('.callback').style.display = 'flex';
};

// закрытие popup
const closePopUp = () => {
  popUp.classList.remove('active');
};

closeBtn.addEventListener('click', closePopUp);
window.addEventListener('keydown', (e) => {
  if (e.keyCode === 27) {
    closePopUp();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('form').addEventListener('submit', async (e) => {
    e.preventDefault();
    const name = nameInput.value;
    const phone = inputPhone.value;
    const email = emailInput.value;
    const dataClient = qs.stringify({
      name,
      phone,
      email,
    });
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: dataClient,
    })
      .then(resp => resp.json())
      .then((data) => {
        openPopUp();
        if (data.result && (iti.isValidNumber() && emailInput.getAttribute('valid') === 'true' && nameInput.value)) {
          clearForm();
          popUptext.innerHTML = 'Спасибо, Ваша заявка успешно отправлена!';
        } else { popUptext.innerHTML = ' Произошла ошибка, заявка не отправлена. Убедитесь, что поля формы заполнены верно и попробуйте снова.'; }
      });
  });
});
