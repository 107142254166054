import $ from 'jquery';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

$(document).ready(() => {
  const owl = $('.owl-carousel');
  owl.owlCarousel(
    {
      nav: false,
      animateIn: true,
      autoplayTimeout: 1000,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      pagination: true,
      loop: true,
      addClassActive: true,
      responsiveClass: true,
      dots: true,
      responsive: {
        320: {
          items: 1,
          center: true,
          margin: 10,
        },
        748: {
          items: 2,
          margin: 15,
        },
        1024: {
          items: 1,
          margin: 20,
        },
        1440: {
          items: 1,
          margin: 35,
          touchDrag: false,
        },
      },
    },
  );
  $('.nav-buttons_item.next').click(() => {
    owl.trigger('next.owl.carousel');
  });
  $('.nav-buttons_item.prev').click(() => {
    owl.trigger('prev.owl.carousel', [300]);
  });

  // часто задаваемые вопросы
  function toggleFaq() {
    $(this).parent().toggleClass('open');
    $(this).next('.js-faq-body').slideToggle();
  }

  $('.js-faq-title').click(toggleFaq);

  // прокрутка к форме
  $(document).on('click', '[data-scrollto*="form"]', (event) => {
    const jsBar = $('.js-bar');
    const offsetTop = jsBar.length > 0 ? jsBar.height() + 10 : 40;
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $('#form').offset().top - offsetTop,
    }, 600);
  });
});
